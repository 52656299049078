<template>
  <div id="app">
    <p>{{ greeting }}</p>
    <p>{{ flaskGreeting }}</p>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
  },
  data: function(){
    return {
      greeting: "Hiya, Venus...",
      flaskGreeting: ""
    }
  },
  created: async function(){
    const host = process.env.VUE_APP_COLOR_STEALER_API
    const gResponse = await fetch(`${host}/`);
    const gObject = await gResponse.json();
    this.flaskGreeting = gObject.greeting;
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
